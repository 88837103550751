import React, {useEffect} from "react";
import { withRouter } from "../utils/customWithRouter";
import { connect } from "react-redux";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import {useLocation, useNavigate} from "react-router-dom";
import {APP_PREFIX_PATH} from "../configs/AppConfig";

export const Views = (props) => {
  const { locale, token } = props;
  const hookLocation = useLocation();
  const navigate = useNavigate();
  const currentAppLocale = AppLocale[locale];
  let shouldRedirectFlag = token != null;

  useEffect(() => {
    if (!shouldRedirectFlag) {
      navigate(`${APP_PREFIX_PATH}/login`)
    }
    else {
      navigate(`${APP_PREFIX_PATH}/dashboard`)
    }
  }, [shouldRedirectFlag])

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        { shouldRedirectFlag ? <AppLayout location={hookLocation.pathname} /> :
          <AuthLayout /> }
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
