import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import Views from "./views";
import store from "./redux/store";
import { THEME_CONFIG } from "./configs/AppConfig";
import axiosUtil from "./services/axios";

axiosUtil.initalise(store)

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router>
            <Routes>
              <Route path="*" element={<Views/>} />
            </Routes>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
