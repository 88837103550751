import CONFIG from "./config";

export const signIn = {
  url: CONFIG.APIURL + "admin/users/login",
  method: "POST",
};

//change password

export const changepassword = {
  url: CONFIG.APIURL + "admin/users/change-password",
  method: "POST",
};

//forget password
export const forgetPasswordOtp = {
  url: CONFIG.APIURL + "admin/users/send/forget-password-otp",
  method: "POST",
};
export const forgetPasswordGetOtp = {
  url: CONFIG.APIURL + "admin/users/verify/otp",
  method: "POST",
};
export const forgetPasswordResetOtp = {
  url: CONFIG.APIURL + "admin/users/reset-password",
  method: "POST",
};
//roles

export const getPermissionList = {
  url: "admin/permissions/roles/permission-list",
  method: "GET",
};
export const getProfile = {
  url: CONFIG.APIURL + "admin/users/detail",
  method: "GET",
};
export const updateProfile = {
  url: CONFIG.APIURL + "admin/users/update",
  method: "POST",
};

export const creditAdd = {
  url: CONFIG.APIURL + "admin/credit/create",
  method: "POST",
};

export const getRoleListWithPagination = {
  url: CONFIG.APIURL + "admin/roles/list",
  method: "GET",
};
export const addRole = {
  url: CONFIG.APIURL + "admin/roles/create",
  method: "POST",
};
export const getRoleList = {
  url: CONFIG.APIURL + "admin/roles/list",
  method: "GET",
};
export const updateRole = {
  url: CONFIG.APIURL + "admin/roles/update",
  method: "POST",
};
export const deleteRole = {
  url: CONFIG.APIURL + "admin/roles/delete",
  method: "DELETE",
};
export const getPermission = {
  url: CONFIG.APIURL + "admin/permissions/list",
  method: "GET",
};
export const getAllowPermission = {
  url: CONFIG.APIURL + "admin/permissions/allow/list",
  method: "GET",
};
export const updatePermission = {
  url: CONFIG.APIURL + "admin/permissions/update-roles-permission",
  method: "POST",
};

//staff
export const getStaffList = {
  url: CONFIG.APIURL + "admin/users/list",
  method: "GET",
};

export const addStaff = {
  url: CONFIG.APIURL + "admin/users/create",
  method: "POST",
};
export const deleteStaff = {
  url: CONFIG.APIURL + "admin/users/delete",
  method: "DELETE",
};
export const updateStaff = {
  url: CONFIG.APIURL + "admin/users/update",
  method: "POST",
};
export const getStaffListWithPagination = {
  url: CONFIG.APIURL + "admin/users/list",
  method: "GET",
};

//Credit type
export const getCreditList = {
  url: CONFIG.APIURL + "admin/credits/list",
  method: "GET",
};
export const addCredit = {
  url: CONFIG.APIURL + "admin/credits/create",
  method: "POST",
};
export const updateCredit = {
  url: CONFIG.APIURL + "admin/credits/update",
  method: "POST",
};
export const deleteCredit = {
  url: CONFIG.APIURL + "admin/credits/delete",
  method: "DELETE",
};
//Ads ons
export const getAddonsList = {
  url: CONFIG.APIURL + "admin/addons/list",
  method: "GET",
};
export const addAddons = {
  url: CONFIG.APIURL + "admin/addons/create",
  method: "POST",
};
export const updateAddons = {
  url: CONFIG.APIURL + "admin/addons/update",
  method: "POST",
};
export const deleteAddons = {
  url: CONFIG.APIURL + "admin/addons/delete",
  method: "POST",
};
//Coupon
export const getCouponList = {
  url: CONFIG.APIURL + "admin/coupon/list",
  method: "GET",
};
export const addCoupon = {
  url: CONFIG.APIURL + "admin/coupon/create",
  method: "POST",
};
export const updateCoupon = {
  url: CONFIG.APIURL + "admin/coupon/update",
  method: "POST",
};
export const deleteCoupon = {
  url: CONFIG.APIURL + "admin/coupon/delete",
  method: "POST",
};

//Goods type
export const getGoodsList = {
  url: CONFIG.APIURL + "admin/goods/list",
  method: "GET",
};
export const addGoods = {
  url: CONFIG.APIURL + "admin/goods/create",
  method: "POST",
};
export const updateGoods = {
  url: CONFIG.APIURL + "admin/goods/update",
  method: "POST",
};
export const deleteGoods = {
  url: CONFIG.APIURL + "admin/goods/delete",
  method: "DELETE",
};
//Base Fare
export const updateBaseFare = {
  url: CONFIG.APIURL + "admin/vehicle/updateVehicleBaseFare",
  method: "POST",
};
export const addBaseFareCity = {
  url: CONFIG.APIURL + "admin/basefares/addBaseFareCity",
  method: "POST",
}
export const updateBaseFareCity = {
  url: CONFIG.APIURL + "admin/basefares/updateBaseFareCity",
  method: "POST",
}
export const getBaseFareCity = {
  url: CONFIG.APIURL + "admin/basefares/listBaseFareCity",
  method: "GET",
}

export const deleteBaseFareCity = {
  url: CONFIG.APIURL + "admin/basefares/deleteBaseFareCity",
  method: "POST",
}

export const getBaseFareRadius = {
  url: CONFIG.APIURL + "admin/basefares/list-basefair-radius",
  method: "GET",
}

export const addBaseFareRadius = {
  url: CONFIG.APIURL + "admin/basefares/add-basefair-radius",
  method: "POST",
}
export const updateBaseFareRadius = {
  url: CONFIG.APIURL + "admin/basefares/update-basefair-radius",
  method: "POST",
}

// export const deleteBaseFareRadius = {
//   url: CONFIG.APIURL + "admin/basefares/delete-basefair-radius",
//   method: "POST",
// }

export const getBaseFareVehicleLocation = {
  url: CONFIG.APIURL + "admin/basefares/listBaseFareLocation",
  method: "GET",
}

export const deleteBaseFareVehicleLocation = {
  url: CONFIG.APIURL + "admin/basefares/deleteBaseFareLocation",
  method: "POST",
}

export const deleteBaseFareRadius = {
  url: CONFIG.APIURL + "admin/basefares/deleteBaseFareRadius",
  method: "POST",
}

export const addBaseFareVehicleLocation = {
  url: CONFIG.APIURL + "admin/basefares/addBaseFareLocation",
  method: "POST",
}
export const updateBaseFareVehicleLocation = {
  url: CONFIG.APIURL + "admin/basefares/updateBaseFareLocation",
  method: "POST",
}
export const getBaseFareVehicleLocationCheckpoint = {
  url: CONFIG.APIURL + "admin/basefares/listBaseFareCheckPoint",
  method: "GET",
}

export const deleteBaseFareVehicleLocationCheckpoint = {
  url: CONFIG.APIURL + "admin/basefares/deleteBaseFareCheckPoint",
  method: "POST",
}

export const addBaseFareVehicleLocationCheckpoint = {
  url: CONFIG.APIURL + "admin/basefares/addBaseFareCheckPoint",
  method: "POST",
}
export const updateBaseFareVehicleLocationCheckpoint = {
  url: CONFIG.APIURL + "admin/basefares/updateBaseFareCheckPoint",
  method: "POST",
}

//State
export const getStates = {
  url: CONFIG.APIURL + "admin/states/list",
  method: "GET",
};

export const addStates = {
  url: CONFIG.APIURL + "admin/states/create",
  method: "POST",
};

export const deleteState = {
  url: CONFIG.APIURL + "admin/states/delete",
  method: "DELETE",
};

export const updateStates = {
  url: CONFIG.APIURL + "admin/states/update",
  method: "POST",
};

//District
export const getDistricts = {
  url: CONFIG.APIURL + "admin/district/list",
  method: "GET",
};

export const addDistrict = {
  url: CONFIG.APIURL + "admin/district/create",
  method: "POST",
};

export const deleteDistrict = {
  url: CONFIG.APIURL + "admin/district/delete",
  method: "DELETE",
};

export const updateDistrict = {
  url: CONFIG.APIURL + "admin/district/update",
  method: "POST",
};

//City
export const getCities = {
  url: CONFIG.APIURL + "admin/cities/list",
  method: "GET",
};

export const addCity = {
  url: CONFIG.APIURL + "admin/cities/create",
  method: "POST",
};

export const deleteCity = {
  url: CONFIG.APIURL + "admin/cities/delete",
  method: "DELETE",
};

export const updateCity = {
  url: CONFIG.APIURL + "admin/cities/update",
  method: "POST",
};

//Supervisor
export const getSupervisors = {
  url: CONFIG.APIURL + "admin/supervisor/list",
  method: "GET",
};

export const addSupervisor = {
  url: CONFIG.APIURL + "admin/supervisor/create",
  method: "POST",
};

export const deleteSupervisor = {
  url: CONFIG.APIURL + "admin/supervisor/delete",
  method: "DELETE",
};

export const updateSupervisor = {
  url: CONFIG.APIURL + "admin/supervisor/update",
  method: "POST",
};

export const getCordinates = {
  url: CONFIG.APIURL + "admin/supervisor/get-coordinates",
  method: "GET",
};




//fleet partner
export const getfleet = {
  url: CONFIG.APIURL + "admin/fleet-partners/list",
  method: "GET",
};
export const addfleetbusiness = {
  url: CONFIG.APIURL + "admin/fleet-partners/create",
  method: "POST",
};
export const updatebusiness = {
  url: CONFIG.APIURL + "admin/fleet-partners/update",
  method: "POST",
};
export const deletebusiness = {
  url: CONFIG.APIURL + "admin/fleet-partners/delete",
  method: "DELETE",
};
//fleet partner details(profile)
export const getfleetdetails = {
  url: CONFIG.APIURL + "admin/fleet-partners/details",
  method: "GET",
};
// fleet partner vehicle

export const getfleetvehicle = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/list",
  method: "GET",
};
export const addfleetvehicle = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/create",
  method: "POST",
};
export const updatefleetvehicle = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/update",
  method: "PUT",
};
export const deletefleetvehicle = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/delete",
  method: "DELETE",
};

export const removeDriver = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/remove-driver",
  method: "DELETE",
};

export const deletefleetdocument = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/delete-document",
  method: "DELETE",
};

export const addFleetContact = {
  url: CONFIG.APIURL + "admin/fleet-partners/create-contact",
  method: "POST",
}
export const getFleetContact = {
  url: CONFIG.APIURL + "admin/fleet-partners/list-contact",
  method: "GET",
}
export const updateFleetContact = {
  url: CONFIG.APIURL + "admin/fleet-partners/update-contact",
  method: "POST",
}
export const deleteFleetContact = {
  url: CONFIG.APIURL + "admin/fleet-partners/delete-contact",
  method: "DELETE",
}
export const moveFleet = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/update-fleetpartner-in-vehicle",
  method: "PATCH",
}

// fleet partner driver

export const getfleetdriver = {
  url: CONFIG.APIURL + "admin/fleet-partners-driver/list",
  method: "GET",
};
export const addfleetdriver = {
  url: CONFIG.APIURL + "admin/fleet-partners-driver/create",
  method: "POST",
};
export const updatefleetdriver = {
  url: CONFIG.APIURL + "admin/fleet-partners-driver/update",
  method: "PUT",
};
export const deletefleetdriver = {
  url: CONFIG.APIURL + "admin/fleet-partners-driver/delete",
  method: "DELETE",
};
export const deleteDriverdocument = {
  url: CONFIG.APIURL + "admin/fleet-partners-driver/delete-document",
  method: "DELETE",
};

//Fleet partner trips

export const getFleetPartnerTrips = {
  url: CONFIG.APIURL + "admin/fleet-partners/trip-list",
  method: "GET"
}

//customer
export const getindividualcustomer = {
  url: CONFIG.APIURL + "admin/customer/list",
  method: "GET",
};

//Customer trips

export const getCustomerTrips = {
  url: CONFIG.APIURL + "admin/customer/trip-list",
  method: "GET"
}

export const getCustomerFixedRoute = {
  url: CONFIG.APIURL + "admin/customer-fixed-route/list",
  method: "GET"
}

//firm
export const addFirm = {
  url: CONFIG.APIURL + "admin/firms/create",
  method: "POST",
};
export const updateFirm = {
  url: CONFIG.APIURL + "admin/firms/update",
  method: "POST",
};
export const firmList = {
  url: CONFIG.APIURL + "admin/firms/list",
  method: "GET",
};
export const firmListWithPagination = {
  url: CONFIG.APIURL + "admin/firms/list",
  method: "GET",
};
export const deleteFirm = {
  url: CONFIG.APIURL + "admin/firms/delete",
  method: "DELETE",
};
export const addPortfolioFirm = {
  url: CONFIG.APIURL + "admin/firms/portfolio/create",
  method: "POST",
};
export const deleteFirmPortFolio = {
  url: CONFIG.APIURL + "admin/firms/portfolio/delete",
  method: "DELETE",
};

//fund
export const addFund = {
  url: CONFIG.APIURL + "admin/funds/create",
  method: "POST",
};
export const updateFund = {
  url: CONFIG.APIURL + "admin/funds/update",
  method: "POST",
};
export const getFunds = {
  url: CONFIG.APIURL + "admin/funds/list",
  method: "GET",
};
export const deleteFunds = {
  url: CONFIG.APIURL + "admin/funds/delete",
  method: "DELETE",
};
export const getUnits = {
  url: CONFIG.APIURL + "admin/funds/unit",
  method: "GET",
};

//tags
export const addTags = {
  url: CONFIG.APIURL + "admin/tags/create",
  method: "POST",
};
export const updateTags = {
  url: CONFIG.APIURL + "admin/tags/update",
  method: "POST",
};
export const getTags = {
  url: CONFIG.APIURL + "admin/tags/list",
  method: "GET",
};
export const getTagsPagination = {
  url: CONFIG.APIURL + "admin/tags/list",
  method: "GET",
};
export const deleteTags = {
  url: CONFIG.APIURL + "admin/tags/delete",
  method: "DELETE",
};
export const firmListing = {
  url: CONFIG.APIURL + "admin/firms/list",
  method: "GET",
};
//FAQ

export const getFaqList = {
  url: CONFIG.APIURL + "admin/faq/list",
  method: "GET",
};
export const addFaq = {
  url: CONFIG.APIURL + "admin/faq/create",
  method: "POST",
};
export const updateFaq = {
  url: CONFIG.APIURL + "admin/faq/update",
  method: "POST",
};
export const deleteFaq = {
  url: CONFIG.APIURL + "admin/faq/delete",
  method: "DELETE",
};

//customer

export const getCustomerList = {
  url: CONFIG.APIURL + "admin/customer/list",
  method: "GET",
};
export const addCustomer = {
  url: CONFIG.APIURL + "admin/customer/create",
  method: "POST",
};
export const updateCustomer = {
  url: CONFIG.APIURL + "admin/customer/update",
  method: "POST",
};
export const deleteCustomer = {
  url: CONFIG.APIURL + "admin/customer/delete",
  method: "DELETE",
};

// customer delete logs
export const getCustomerDeleteLogs = {
  url: CONFIG.APIURL + "admin/customer/customer-delete-log-list",
  method: "GET",
};


export const getAddressList = {
  url: CONFIG.APIURL + "admin/customer-address/list",
  method: "GET",
};
export const addAddress = {
  url: CONFIG.APIURL + "admin/customer-address/create",
  method: "POST",
};
export const updateAddress = {
  url: CONFIG.APIURL + "admin/customer-address/update",
  method: "POST",
};
export const deleteAddress = {
  url: CONFIG.APIURL + "admin/customer-address/delete",
  method: "DELETE",
};
//customer contact
export const getContactList = {
  url: CONFIG.APIURL + "admin/customer-contact/list",
  method: "GET",
};
export const addContact = {
  url: CONFIG.APIURL + "admin/customer-contact/create",
  method: "POST",
};
export const updateContact = {
  url: CONFIG.APIURL + "admin/customer-contact/update",
  method: "POST",
};
export const deleteContact = {
  url: CONFIG.APIURL + "admin/customer-contact/delete",
  method: "DELETE",
};

//Fixed route for corporate user

export const addFixedRoute = {
  url: CONFIG.APIURL + "admin/customer-fixed-route/create",
  method: "POST",
};

export const deleteFixedRoutePrice = {
  url: CONFIG.APIURL + "admin/customer-fixed-route/delete",
  method: "DELETE",
};

export const updateFixedRoutePrice = {
  url: CONFIG.APIURL + "admin/customer-fixed-route/update",
  method: "POST",
};

//Get Address list for  fixed route
export const getGoogleAddressList = {
  url: CONFIG.APIURL + "admin/customer-fixed-route/list-address",
  method: "GET",
};


//setting booking restriction
export const getBookingRestrictionList = {
  url: CONFIG.APIURL + "admin/booking-restrictions/list",
  method: "GET",
};
export const addBookingRestriction = {
  url: CONFIG.APIURL + "admin/booking-restrictions/create",
  method: "POST",
};
//setting Payment Setting
export const getPaymentList = {
  url: CONFIG.APIURL + "admin/payment-setting/list",
  method: "GET",
};
export const updatePayment = {
  url: CONFIG.APIURL + "admin/payment-setting/update",
  method: "POST",
};


//setting Tax setting
export const getTaxNameList = {
  url: CONFIG.APIURL + "admin/tax-setting/tax-list",
  method: "GET",
};
export const getTaxList = {
  url: CONFIG.APIURL + "admin/tax-setting/list",
  method: "GET",
};
export const createTex = {
  url: CONFIG.APIURL + "admin/tax-setting/create",
  method: "POST",
};
export const updateTex = {
  url: CONFIG.APIURL + "admin/tax-setting/update",
  method: "POST",
};
export const deleteTex = {
  url: CONFIG.APIURL + "admin/tax-setting/delete",
  method: "DELETE",
};


// trip status
export const updateTripStatus = {
  url: CONFIG.APIURL + "admin/trip-status/update",
  method: "POST",
};
export const getTripStatus = {
  url: CONFIG.APIURL + "admin/trip-status/list",
  method: "GET",
};


//expense type

export const getExpenseType = {
  url: CONFIG.APIURL + "admin/expense-type/list",
  method: "GET",
};
export const createExpenseType = {
  url: CONFIG.APIURL + "admin/expense-type/create",
  method: "POST",
};
export const updateExpenseType = {
  url: CONFIG.APIURL + "admin/expense-type/update",
  method: "POST",
};
export const deleteExpensetype = {
  url: CONFIG.APIURL + "admin/expense-type/delete",
  method: "DELETE",
};



//other
export const createMiscellous = {
  url: CONFIG.APIURL + "admin/miscellaneous-type/create",
  method: "POST",
};
export const getMiscellous = {
  url: CONFIG.APIURL + "admin/miscellaneous-type/list",
  method: "GET",
};

// vechicel
export const createVehicel = {
  url: CONFIG.APIURL + "admin/vehicle/create",
  method: "POST",
};
export const createVehicelFareType = {
  url: CONFIG.APIURL + "admin/vehicle/addVehicleFaretype",
  method: "POST",
};

export const updateVehicel = {
  url: CONFIG.APIURL + "admin/vehicle/update",
  method: "POST",
};
export const getVehicelList = {
  url: CONFIG.APIURL + "admin/vehicle/list",
  method: "GET",
};

export const getBodyList = {
  url: CONFIG.APIURL + "admin/vehicle/list-body-type",
  method: "GET",
};

export const getCityList = {
  url: CONFIG.APIURL + "admin/vehicle/city-list",
  method: "GET"
}

export const deleteVehicel = {
  url: CONFIG.APIURL + "admin/vehicle/delete",
  method: "POST",
};

export const profileVehicelList = {
  url: CONFIG.APIURL + "admin/fleet-partners-vehicle/vehicle-list",
  method: "GET",
};

export const profileDriverList = {
  url: CONFIG.APIURL + "admin/fleet-partners-driver/driver-list",
  method: "GET",
};

//Booking manual ride

export const getNearByDriver = {
  url: CONFIG.APIURL + "admin/booking/getNearByVehicleDrivers",
  method: "POST",
};

export const validateCoupon = {
  url: CONFIG.APIURL + "admin/booking/checkCouponCode",
  method: "POST",
};

export const checkDriverCalculation = {
  url: CONFIG.APIURL + "admin/booking/checkDriverCalculation",
  method: "GET",
}

export const getAddressForCorporateCustomer = {
  url: CONFIG.APIURL + "admin/booking/getAddressForCorporateUser",
  method: "GET",
}

export const checkGoodsInsurance = {
  url: CONFIG.APIURL + "admin/booking/checkGoodsInsurance",
  method: "POST",
}

export const addRide = {
  url: CONFIG.APIURL + "admin/booking/addBooking",
  method: "POST"
}

export const updateBooking = {
  url: CONFIG.APIURL + "admin/booking/updateBooking",
  method: "POST"
}

//get Ref booking
export const getBookingByRefId = {
  url: CONFIG.APIURL + "admin/booking/get-multibooking",
  method: "GET"
}

// Get new baseFare based on selected driver locations
export const getNewBaseFare = {
  url: CONFIG.APIURL + "admin/booking/get-new-basefare",
  method: "POST"
}

export const uploadInvoiceApi = {
  url: CONFIG.APIURL + "admin/booking/updateFileBooking",
  method: "POST"
}

export const listBooking = {
  url: CONFIG.APIURL + "admin/booking/listBooking",
  method: "GET"
}
export const cancleBooking = {
  url: CONFIG.APIURL + "admin/booking/cancel-booking",
  method: "POST"
}


export const updateBookingStatus = {
  url: CONFIG.APIURL + "admin/booking/update-booking-status",
  method: "POST"
}

export const updateChallan = {
  url: CONFIG.APIURL + "admin/booking/update-booking-challan",
  method: "POST"
}
export const downloadLorryReceipt = {
  url: CONFIG.APIURL + "admin/users/download-booking-lorry-receipt",
  method: "GET"
}
//Get role list of account manager
export const accountListManager = {
  url: CONFIG.APIURL + "admin/customer/role-list",
  method: "GET"
}

//get Industry List
export const industryList = {
  url: CONFIG.APIURL + "admin/industry/list",
  method: "GET"
}

//FeedBack

export const feedBackList = {
  url: CONFIG.APIURL + "admin/feedback/list",
  method: "GET"
}

export const feedBackDelete = {
  url: CONFIG.APIURL + "admin/feedback/delete",
  method: "DELETE"
}

export const listPages = {
  url: CONFIG.APIURL + "admin/pages/list",
  method: "GET"
}

export const addPages = {
  url: CONFIG.APIURL + "admin/pages/create",
  method: "POST"
}

export const addNotification = {
  url: CONFIG.APIURL + "admin/notification/create",
  method: "POST"
}

export const getNotification = {
  url: CONFIG.APIURL + "admin/notification/list",
  method: "GET"
}

export const deleteNotification = {
  url: CONFIG.APIURL + "admin/notification/delete",
  method: "POST"
}

//Dashboard
export const getTodayBookingList = {
  url: CONFIG.APIURL + "admin/dashboard/today-booking-list",
  method: "GET"
}
export const BookingListByStatus = {
  url: CONFIG.APIURL + "admin/dashboard/booking-list-by-status",
  method: "GET"
}
export const listTodayInfo = {
  url: CONFIG.APIURL + "admin/dashboard/today-info-list",
  method: "GET"
}
export const listNewFleetPartner = {
  url: CONFIG.APIURL + "admin/dashboard/new-fleet-partner-list",
  method: "GET"
}
export const listNewCustomer = {
  url: CONFIG.APIURL + "admin/dashboard/new-customer-list",
  method: "GET"
}
export const listNewDriver = {
  url: CONFIG.APIURL + "admin/dashboard/new-driver-list",
  method: "GET"
}
export const listTopCustomer = {
  url: CONFIG.APIURL + "admin/dashboard/top-customer-list",
  method: "GET"
}
export const listSupervisor = {
  url: CONFIG.APIURL + "admin/dashboard/supervisor-list",
  method: "GET"
}
export const listBookingGraph = {
  url: CONFIG.APIURL + "admin/dashboard/weekly-booking-list",
  method: "GET"
}
export const listPerformanceGraph = {
  url: CONFIG.APIURL + "admin/dashboard/weekly-new-registration-list",
  method: "GET"
}
export const listCustomer = {
  url: CONFIG.APIURL + "admin/report/company-list",
  method: "GET"
}
export const listCustomerReport = {
  url: CONFIG.APIURL + "admin/report/customer",
  method: "POST"
}
export const listFleetPartnerReport = {
  url: CONFIG.APIURL + "admin/report/fleet-partner",
  method: "POST"
}
export const downloadReport = {
  url: CONFIG.APIURL + "admin/report/download",
  method: "GET"
}

// Consignmnet Note Data
export const getConsignmnetNoteData = {
  url: CONFIG.APIURL + "admin/consignment-note-data/list",
  method: "GET",
};

export const addConsignmnetNoteData = {
  url: CONFIG.APIURL + "admin/consignment-note-data/create",
  method: "POST",
};

export const deleteConsignmnetNoteData = {
  url: CONFIG.APIURL + "admin/consignment-note-data/delete",
  method: "DELETE",
};

export const updateConsignmnetNoteData = {
  url: CONFIG.APIURL + "admin/consignment-note-data/update",
  method: "POST",
};

// Generate Consignment note

export const getConsignmnetNote = {
  url: CONFIG.APIURL + "admin/booking/list-consignmentNote",
  method: "GET",
};

export const addConsignmnetNote = {
  url: CONFIG.APIURL + "admin/booking/create-consignmentNote",
  method: "POST",
};

export const deleteConsignmnetNote = {
  url: CONFIG.APIURL + "admin/booking/delete",
  method: "DELETE",
};

export const updateConsignmnetNote = {
  url: CONFIG.APIURL + "admin/booking/update-consignmentNote",
  method: "POST",
};
export const downloadConsignmnetNote = {
  url: CONFIG.APIURL + "admin/booking/download-consignmentNote",
  method: "POST",
};

// WhatsApp Message Service
export const sendWhatsappConfirmationMessage = {
  url: CONFIG.APIURL + "admin/booking/whatsapp_booking_confirmation",
  method: "POST",
};
export const sendWhatsappUpdateMessage = {
  url: CONFIG.APIURL + "admin/booking/whatsapp_booking_update",
  method: "POST",
};
export const sendWhatsappConsignmentNoteMessage = {
  url: CONFIG.APIURL + "admin/booking/whatsapp_consignment_note",
  method: "POST",
};