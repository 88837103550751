import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'

import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const routeList = [
  {
    path: `${APP_PREFIX_PATH}/vehicle-type`,
    Component: lazy(() => import(`./vehicle/vehicle-type`)),
    permission: 'vehicles-list'
  },
  {
    path: `${APP_PREFIX_PATH}/vehicle-type-insert`,
    Component: lazy(() => import(`./vehicle/vehicle-insert`)),
    permission: 'vehicles-create'
  }, {
    path: `${APP_PREFIX_PATH}/vehicle-type-edit`,
    Component: lazy(() => import(`./vehicle/vehicle-edit`)),
    permission: 'vehicles-update'
  },
  {
    path: `${APP_PREFIX_PATH}/add-on`,
    Component: lazy(() => import(`./add-on/add-on`)),
    permission: 'addons-list'
  },
  {
    path: `${APP_PREFIX_PATH}/add-on-insert`,
    Component: lazy(() => import(`./add-on/add-on-insert`)),
    permission: 'addons-create'
  },
  {
    path: `${APP_PREFIX_PATH}/add-on-edit`,
    Component: lazy(() => import(`./add-on/add-on-edit`)),
    permission: 'addons-update'
  }, {
    path: `${APP_PREFIX_PATH}/base-fare`,
    Component: lazy(() => import(`./base-fare/base-fare`)),
    permission: 'vehicles-list'
  },
  {
    path: `${APP_PREFIX_PATH}/edit-base-fare`,
    Component: lazy(() => import(`./base-fare/edit-basefare`)),
    permission: 'vehicles-update'
  },
  // {
  //   path:`${APP_PREFIX_PATH}/base-fare-vehicletype`,
  //   Component:lazy(() => import(`./base-fare/basefare-vehicletype`)),
  //   permission:'basefarecity-list'
  // },
  {
    path: `${APP_PREFIX_PATH}/base-fare/base-fare-vehicletype`,
    Component: lazy(() => import(`views/app-views/base-fare/basefare-vehicle-location`)),
    permission: 'basefarelocation-list'
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-add-checkpoint`,
    Component: lazy(() => import(`views/app-views/base-fare/add-update-checkpoint`)),
    permission: 'basefarecheckpoint-create'
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-edit-checkpoint`,
    Component: lazy(() => import(`views/app-views/base-fare/add-update-checkpoint`)),
    permission: 'basefarecheckpoint-update'
  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-add-pickPoint`,
    Component: lazy(() => import(`views/app-views/base-fare/add-update-location`)),
    permission: 'basefarelocation-create'

  },
  {
    path: `${APP_PREFIX_PATH}/base-fare-update-pickPoint`,
    Component: lazy(() => import(`views/app-views/base-fare/add-update-location`)),
    permission: 'basefarelocation-update'

  },
  // {
  //   path:`${APP_PREFIX_PATH}/addfare-on-radius`,
  //   Component:lazy(() => import(`./base-fare/addfare-on-radius`)),
  //   permission:'basefarecheckpoint-update'
  // },
  {
    path: `${APP_PREFIX_PATH}/goods`,
    Component: lazy(() => import(`./goods-type/goods`)),
    permission: 'goods-list'
  }, {
    path: `${APP_PREFIX_PATH}/goods-add`,
    Component: lazy(() => import(`./goods-type/goods-add`)),
    permission: 'goods-create'
  }, {
    path: `${APP_PREFIX_PATH}/goods-edit`,
    Component: lazy(() => import(`./goods-type/goods-edit`)),
    permission: 'goods-update'
  }, {
    path: `${APP_PREFIX_PATH}/coupons`,
    Component: lazy(() => import(`./coupons/coupons`)),
    permission: 'coupon-list'
  },
  {
    path: `${APP_PREFIX_PATH}/coupons-insert`,
    Component: lazy(() => import(`./coupons/coupons-insert`)),
    permission: 'coupon-create'
  },
  {
    path: `${APP_PREFIX_PATH}/coupons-edit`,
    Component: lazy(() => import(`./coupons/coupons-edit`)),
    permission: 'coupon-update'
  },
  {
    path: `${APP_PREFIX_PATH}/credit`,
    Component: lazy(() => import(`./credit-type/credit`)),
    permission: 'credits-list'
  },
  {
    path: `${APP_PREFIX_PATH}/credit-insert`,
    Component: lazy(() => import(`./credit-type/credit-insert`)),
    permission: 'credits-create'
  },
  {
    path: `${APP_PREFIX_PATH}/credit-edit`,
    Component: lazy(() => import(`./credit-type/credit-edit`)),
    permission: 'credits-update'
  },
  {
    path: `${APP_PREFIX_PATH}/states`,
    Component: lazy(() => import(`./states`)),
    permission: 'states-list'
  },
  {
    path: `${APP_PREFIX_PATH}/states-add`,
    Component: lazy(() => import(`./states/states-add`)),
    permission: 'states-create'
  },
  {
    path: `${APP_PREFIX_PATH}/states-edit`,
    Component: lazy(() => import(`./states/states-add`)),
    permission: 'states-update'
  },
  {
    path: `${APP_PREFIX_PATH}/districts`,
    Component: lazy(() => import(`./districts`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/districts-add`,
    Component: lazy(() => import(`./districts/districts-add`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/districts-edit`,
    Component: lazy(() => import(`./districts/districts-add`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/cities`,
    Component: lazy(() => import(`./cities/`)),
    permission: 'cities-list'
  },
  {
    path: `${APP_PREFIX_PATH}/cities-add`,
    Component: lazy(() => import(`./cities/cities-add`)),
    permission: 'cities-create'
  },
  {
    path: `${APP_PREFIX_PATH}/cities-edit`,
    Component: lazy(() => import(`./cities/cities-add`)),
    permission: 'cities-update'
  },
  //   {
  //     path:`${APP_PREFIX_PATH}/districts`,
  //     Component:lazy(() => import(`./districts`)),
  //     permission:''
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/districts-add`,
  //     Component:lazy(() => import(`./districts/districts-add`)),
  //     permission:''
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/districts-edit`,
  //     Component:lazy(() => import(`./districts/districts-add`)),
  //     permission:''
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/cities`,
  //     Component:lazy(() => import(`./cities/`)),
  //     permission:'cities-list'
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/cities-add`,
  //     Component:lazy(() => import(`./cities/cities-add`)),
  //     permission:'cities-create'
  //   },
  //   {
  //     path:`${APP_PREFIX_PATH}/cities-edit`,
  //     Component:lazy(() => import(`./cities/cities-add`)),
  //     permission:'cities-update'
  //   },

  {
    path: `${APP_PREFIX_PATH}/customers`,
    Component: lazy(() => import(`./customers`)),
    permission: 'customer-list'
  },
  {
    path: `${APP_PREFIX_PATH}/individual-user`,
    Component: lazy(() => import(`./customers/individual-user/individual-user`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/customers-corporate-user-add`,
    Component: lazy(() => import(`./customers/corporate/add-corporate-user`)),
    permission: 'customer-create'
  },
  {

    path: `${APP_PREFIX_PATH}/customers-add-fixed-route`,
    Component: lazy(() => import(`./customers/corporate/profile/add-fixed-route`)),
    permission: 'customer-create'
  },
  {
    path: `${APP_PREFIX_PATH}/customers-corporate-profile`,
    Component: lazy(() => import(`./customers/corporate/profile/user-profile`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/customers-add-individual-user`,
    Component: lazy(() => import(`./customers/individual-user/add-individual-user`)),
    permission: 'customer-create'
  },
  {
    path: `${APP_PREFIX_PATH}/customers-user-profile`,
    Component: lazy(() => import(`./customers/individual-user/profile/user-profile`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/update-customers-user-profile`,
    Component: lazy(() => import(`./customers/individual-user/profile/individual-user-profile`)),
    permission: 'customer-update'
  },
  {
    path: `${APP_PREFIX_PATH}/update-customers-corporate-user-profile`,
    Component: lazy(() => import(`./customers/corporate/profile/corporate-individual-user-profile`)),
    permission: 'customer-update'
  },
  {
    path: `${APP_PREFIX_PATH}/supervisor`,
    Component: lazy(() => import(`./supervisor`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/supervisor-add`,
    Component: lazy(() => import(`./supervisor/supervisor-add`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/supervisor-edit`,
    Component: lazy(() => import(`./supervisor/supervisor-add`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners`,
    Component: lazy(() => import(`./fleet-partners`)),
    permission: 'fleet-partners-list'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-vehicle-details`,
    Component: lazy(() => import(`./fleet-partners/vehicle-details`)),
    permission: 'fleet-partners-list'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-driver-details`,
    Component: lazy(() => import(`./fleet-partners/driver_details`)),
    permission: 'fleet-partners-list'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-profile`,
    Component: lazy(() => import(`./fleet-partners/profile/user-profile`)),
    permission: 'fleet-partners-list'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-add`,
    Component: lazy(() => import(`./fleet-partners/add-fleetpartner`)),
    permission: 'fleet-partners-create'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-edit-vehicle`,
    Component: lazy(() => import(`./fleet-partners/edit-vehicle`)),
    permission: 'fleet-partners-update'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-booking-details`,
    Component: lazy(() => import(`./booking/view-detail`)),
    permission: 'fleet-partners-list'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-update`,
    Component: lazy(() => import(`./fleet-partners/profile/individual-user-profile`)),
    permission: 'fleet-partners-update'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-update-driver`,
    Component: lazy(() => import(`./fleet-partners/profile/AddUpdateDriver`)),
    permission: 'fleet-partners-update'
  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-add-driver`,
    Component: lazy(() => import(`./fleet-partners/profile/AddUpdateDriver`)),
    permission: 'fleet-partners-create'

  },
  {
    path: `${APP_PREFIX_PATH}/fleet-partners-edit-driver`,
    Component: lazy(() => import(`./fleet-partners/profile/AddUpdateDriver`)),
    permission: 'fleet-partners-update'
  },
  {
    path: `${APP_PREFIX_PATH}/settings`,
    Component: lazy(() => import(`./settings`)),
    permission: ["booking-restrictions-create", "payment-setting-list", "tax-setting-list"
      , "trip-status-list", "expense-type-list", "miscellaneous-type-list"],
  },
  {
    path: `${APP_PREFIX_PATH}/role`,
    Component: lazy(() => import(`./roles/role`)),
    permission: 'roles-list'
  },
  {
    path: `${APP_PREFIX_PATH}/role-add`,
    Component: lazy(() => import(`./roles/role-insert`)),
    permission: 'roles-create'
  },
  {
    path: `${APP_PREFIX_PATH}/role-edit`,
    Component: lazy(() => import(`./roles/role-edit`)),
    permission: 'roles-update'
  },
  {
    path: `${APP_PREFIX_PATH}/manage-permission`,
    Component: lazy(() => import(`./roles/managePermission`)),
    permission: 'permissions-update-roles-permission'
  },
  {
    path: `${APP_PREFIX_PATH}/feedback`,
    Component: lazy(() => import(`./feedback/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/terms-and-conditions`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/privacy`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/about`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/payment`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/refunds-and-cancellation-fleet`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/refunds-and-cancellation-customer`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/trip`,
    Component: lazy(() => import(`./page/index`)),
    permission: ''
  },
  // Consignment note data routes
  {
    path: `${APP_PREFIX_PATH}/consignment-note-data`,
    Component: lazy(() => import(`./consignment-note-data`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/consignor-consignee-add`,
    Component: lazy(() => import(`./consignment-note-data/consignor-consignee-add`)),
    permission: ''
  },
  {
    path: `${APP_PREFIX_PATH}/consignor-consignee-edit`,
    Component: lazy(() => import(`./consignment-note-data/consignor-consignee-add`)),
    permission: ''
  },
  
  // Customer delete logs routes

   {
    path: `${APP_PREFIX_PATH}/customer-delete-logs`,
    Component: lazy(() => import(`./customers-delete-logs`)),
    permission: ''
  },
]


export const AppViews = () => {
  const auth = useSelector(state => state.auth)
  const allowedPermission = auth?.roleInformation?.map(role => role.permission_id.name)

  const roleSlag = localStorage.getItem("role_slag");

  const getRoute = (route) => {
    if (roleSlag === "super-admin" || route.permission === '') {
      return <Route
          key={route.path}
        path={route.path}
        Component={route.Component}
        exact
      />
    } else {
      if (route.permission !== '') {
        if (typeof route.permission === 'string') {
          if (allowedPermission && allowedPermission.includes(route.permission))
            return <Route
                key={route.path}
              path={route.path}
              Component={route.Component}
              exact
            />
        } else {
          if (allowedPermission && route?.permission?.some(r => allowedPermission.includes(r)))
            return <Route
                key={route.path}
              path={route.path}
              Component={route.component}
              exact
            />
        }
      }
    }
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>

        <Route
          path={`${APP_PREFIX_PATH}/dashboard/*`}
          Component={lazy(() => import(`./dashboards/default`))}
          exact
        />
        {
          routeList.map(route => getRoute(route))
        }
        <Route
          path={`${APP_PREFIX_PATH}/addfare-on-radius`}
          Component={lazy(() => import(`views/app-views/base-fare/add-update-fare-on-radius`))}
          exact
        />
        {/*<Route*/}
        {/*  path={`${APP_PREFIX_PATH}/editfare-on-radius`}*/}
        {/*  Component={lazy(() => import(`./base-fare/editfare-on-radius`))}*/}
        {/*  exact*/}
        {/*/>*/}
        {
          roleSlag === 'supervisor' && <Route
            path={`${APP_PREFIX_PATH}/fleet-partners-add-vehicle`}
            Component={lazy(() => import(`./fleet-partners/add-fleetpartner`))}
            exact
          />
        }
        <Route
          path={`${APP_PREFIX_PATH}/vehicle-details`}
          Component={lazy(() => import(`./fleet-partners/vehicle-details`))}
          exact
        />
        {/* Accessible to all user */}
        {/* <Route
          path={`${APP_PREFIX_PATH}/report`}
          Component={lazy(() => import(`./report/report-individual`))}
          exact
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/booking-report`}
          Component={lazy(() => import(`./report/booking-report`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/bookings`}
          Component={lazy(() => import(`./booking`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/assign-driver`}
          Component={lazy(() => import(`./booking/assign-driver`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/bookings-view-detail`}
          Component={lazy(() => import(`./booking/view-detail`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/bookings-add-ride`}
          Component={lazy(() => import(`./booking/add-ride`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/update-bookings`}
          Component={lazy(() => import(`./booking/add-ride`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/staff-add`}
          Component={lazy(() => import(`./staff/staff-insert`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/staff`}
          Component={lazy(() => import(`./staff/staff`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/staff-edit`}
          Component={lazy(() => import(`./staff/staff-update`))}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/notification`}
          Component={lazy(() => import(`./notification/notification`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/notification-details`}
          Component={lazy(() => import(`./notification/notification`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/send-notification`}
          Component={lazy(() => import(`./notification/send-notification`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/payouts`}
          Component={lazy(() => import(`./payout/payouts`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/payment-details`}
          Component={lazy(() => import(`./payout/payment-details`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/manage-wallet`}
          Component={lazy(() => import(`./wallet/manage-wallet`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/add-wallet`}
          Component={lazy(() => import(`./wallet/add-wallet`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/rating`}
          Component={lazy(() => import(`./rating/rating`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/support`}
          Component={lazy(() => import(`./support/support`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqs`}
          Component={lazy(() => import(`./faqs/faqs`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqs-edit`}
          Component={lazy(() => import(`./faqs/faqs-edit`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/faqs-insert`}
          Component={lazy(() => import(`./faqs/faqs-insert`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/edit-profile`}
          Component={lazy(() => import(`./edit-profile/edit-profile`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/change-password`}
          Component={lazy(() => import(`./change-password/change-password`))}
          exact
        />
        {/* <Route
          path={`${APP_PREFIX_PATH}/report-individual`}
          Component={lazy(() => import(`./report/report-individual`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-corporate`}
          Component={lazy(() => import(`./report/report-corporate`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-fleet-partner`}
          Component={lazy(() => import(`./report/report-fleet-partner`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-booking-individual`}
          Component={lazy(() => import(`./report/report-booking-individual`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/report-booking-corporate`}
          Component={lazy(() => import(`./report/report-booking-corporate`))}
          exact
        /> */}
        <Route
          path={`${APP_PREFIX_PATH}/map`}
          Component={lazy(() => import(`./Map`))}
          exact
        />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppViews);
